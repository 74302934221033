body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
  font-family: "Arial", sans-serif;
}

.qr-code-container {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.qr-code-container h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.qr-code-container a {
  text-decoration: none;
  color: #007bff;
}

.qr-code-container a:hover {
  text-decoration: underline;
}

.qr-code-container img {
  margin: 20px 0;
  max-width: 100%;
  height: auto;
}
